<template>
  <div class="flex items-center cursor-pointer">
    <div
      class="relative border-2 border-secodary rounded"
      :class="[{ 'bg-nav_color': modelValue }, size]"
      @click="toggle"
    >
      <v-icon icon="mdi mdi-check-bold" :size="size" class="absolute pb-[11px] md:pb-[10px]  text-text_alt_color" :class="{ hidden: !modelValue }" />
    </div>

    <div @click="labelToggle">
      <slot> </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  modelValue: { type: Boolean, default: false },
  size: { type: String, default: 'w-5 h-5' },
  label: { type: Boolean, default: true }
})

const emit = defineEmits(['update:modelValue'])

const labelToggle = () => props.label && toggle()

const toggle = () => {
  emit('update:modelValue', !props.modelValue)
}
</script>
